<template>
    <TemplateContent
        v-if="entry"
        :entry="entry"
    >
        <template #hero>
            <HeroDetail
                :entry="entry"
                layout="wide/2-columns"
            >
                <template #preTitle>
                    <EntryMetadata
                        :entry="entry"
                        :show-post-date="false"
                        class="entry-metadata--hero"
                    >
                        <BaseTag>Informatie</BaseTag>
                    </EntryMetadata>
                </template>
            </HeroDetail>
        </template>
    </TemplateContent>
</template>

<script setup>
const { variables } = useCraftGraphql();

const response = await useGuardedQuery('/page', variables);

const { craftEntry: entry } = useCraftPage(response);
</script>
